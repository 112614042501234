<template>
  <div class="wrapper finance">
    <el-row class="main">
      <aside class="menu-expanded" v-show="!collapsed">
        <!--导航菜单-->
        <div class="menu">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="" name="1">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-files"></el-icon>快捷菜单
                </div></template
              >
              <ul class="menuList">
                <li
                  v-for="item in menuList"
                  :class="actived == item.val ? 'actived' : ''"
                  :key="item.val"
                >
                  {{ item.name }}
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
      </aside>
      <section
        class="content-container clearfix scollDom"
        :class="collapsed ? 'screen' : ''"
      >
        <el-col :span="24">
          <el-row class="searchBox">
            <el-form @submit.native.prevent :model="filter" ref="filter">
              <div class="fl searchL">
                <el-form-item label style="margin-bottom: 20px">
                  <el-input
                    class="search"
                    v-model="filter.filter"
                    @keyup.enter.native="submitForm('filter')"
                    placeholder="请输入项目名称 / 发票号 / 候选人姓名 / 顾问姓名"
                  >
                    <el-button
                      type="primary"
                      slot="append"
                      @click="submitForm('filter')"
                      >搜 索</el-button
                    >
                  </el-input>
                </el-form-item>
              </div>
              <el-checkbox
                @change="submitForm('filter')"
                v-model="filter.onlySpecial"
                style="margin: 8px 8px 8px 15px"
              ></el-checkbox
              ><span style="color: #606266; font-size: 13px">只看特殊费用</span>
              <el-checkbox
                @change="submitForm('filter')"
                v-model="filter.showDisabledUser"
                style="margin: 8px 8px 8px 15px"
              ></el-checkbox
              ><span style="color: #606266; font-size: 13px">展示停用账户</span>
            </el-form>
          </el-row>
          <el-row :gutter="24" class="pd40">
            <el-col class="table-top">
              <el-pagination
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                v-if="filter.page.total > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[10, 20, 50, 100]"
              ></el-pagination>
            </el-col>
            <el-col :span="24">
              <el-table
                :header-cell-style="{
                  'background-color': '#f5f7fa',
                  height: '35px',
                }"
                v-horizontal-scroll
                :data="ListData"
                v-sticky="{ top: 0, parent: '.scollDom' }"
                class="tabBorder custor"
                v-loading="listLoading"
                ref="tableList"
                :row-style="tableColor"
              >
                <el-table-column
                  prop="id"
                  label="编号"
                  width="60"
                  align="center"
                  fixed
                >
                </el-table-column>
                <el-table-column
                  prop="resumeName"
                  label="候选人姓名"
                  width="120"
                  fixed
                >
                </el-table-column>
                <el-table-column
                  prop="status"
                  fixed
                  label="财务状态"
                  width="130"
                >
                </el-table-column>
                <el-table-column
                  prop="companyName"
                  label="客户名称"
                  show-overflow-tooltip
                  width="250"
                >
                </el-table-column>
                <el-table-column
                  prop="projectName"
                  label="项目名称"
                  show-overflow-tooltip
                  width="250"
                >
                </el-table-column>
                <el-table-column
                  prop="serviceMoney"
                  width="150"
                  label="服务费用"
                >
                </el-table-column>
                <el-table-column
                  prop="activeInvoiceMoney"
                  label="已开票金额"
                  width="100"
                >
                </el-table-column>
                <el-table-column prop="payType" label="开票类型" width="150">
                  <template slot-scope="scope">
                  {{ scope.row.invoiceType+'-'+scope.row.payType }}
                  </template>
                </el-table-column>
                <el-table-column prop="payAmount" label="开票金额" width="150">
                  <template slot-scope="scope">
                    <span style="margin-right: 8px; display: inline-block">{{
                      scope.row.payAmount ? scope.row.payAmount : "/"
                    }}</span>
                    <span
                      v-if="scope.row.payType == '特殊费用'"
                      class="tag tag09"
                      >特殊</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="noTaxMoney"
                  label="不计税金额"
                  width="150"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.noTaxMoney ? scope.row.noTaxMoney : "-"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="accountMoney" label="到账金额">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.accountMoney ? scope.row.accountMoney : "-"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceNumber"
                  label="发票号"
                  width="150"
                >
                </el-table-column>
                <el-table-column
                  prop="remark"
                  show-overflow-tooltip
                  label="顾问备注"
                  width="250"
                >
                </el-table-column>
                <el-table-column
                  prop="createdTime"
                  label="创建时间"
                  width="150"
                >
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="80"
                  :fixed="ListData.length > 0 ? 'right' : false"
                  align="center"
                >
                  <template #default="scope">
                    <span class="tabHref" @click="goDetails(scope.row)"
                      >查看</span
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                v-if="filter.page.total > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[10, 20, 50, 100]"
              ></el-pagination>
            </el-col>
          </el-row>
        </el-col>
      </section>
    </el-row>
    <el-drawer
      :visible.sync="dialogVisibleFinancial"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleCloseFinancialRecord"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-pl_cjcw"></i>财务详情
      </div>
      <financeRecord
        :financeTaskId="currentFinancialTask.id"
        v-if="isFinancialRecord"
        :projectResumeId="currentFinancialTask.projectResumeId"
        :handleFinance="$route.path == '/financeEnquiries' ? false : true"
        :isFinance="false"
      ></financeRecord>
    </el-drawer>
  </div>
</template>

<script>
import {
  queryPagedList, //获取财务列表
} from "../../api/api";
import { mapGetters } from "vuex";
import financeRecord from "../../components/finance/record";
export default {
  name: "financeEnquiries",
  components: { financeRecord },
  data() {
    return {
      activeNames: "1",
      actived: "财务查询",
      menuList: [
        {
          name: "财务查询",
          val: "财务查询",
        },
      ],
      //表单筛选
      filter: {
        filter: "",
        onlySpecial: false,
        showDisabledUser: false,
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: true,
              column: "",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          records: [],
          total: 0,
        },
      },
      ListData: [],
      listLoading: false,
      dialogVisibleFinancial: false,
      isFinancialRecord: false,
    };
  },
  computed: {
    ...mapGetters({
      collapsed: "upDateCollapsed",
    }),
  },
  created() {
    this.filter.filter = this.$route.query.userName;
    this.loadList();
  },
  destroyed() {
    this.canScroll();
  },
  mounted() {
    this.noScroll(); //禁止滚动
  },
  methods: {
    //导航菜单切换
    handleChange() {},
    //切换页数
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.loadList();
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadList();
    },
    //清空
    reset() {
      this.filter = {
        filter: "",
        onlySpecial: false,
        type: "",
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: true,
              column: "",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      };
    },
    //表单搜索
    submitForm() {
      this.filter.page.current = 1;
      this.loadList();
    },
    // 获取财务列表
    loadList() {
      $(".scollDom").scrollTop(0);
      this.listLoading = true;
      this.dialogVisible = false;
      this.isCheckInfo = false;
      queryPagedList(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          if (this.filter.favoriteId && this.ListData.length) {
            this.isShowDelBtn = true;
          }
          this.ListData.forEach((element) => {
            element["show"] = false;
          });
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //表格颜色
    tableColor({ row, rowIndex }) {
      let stylejson = {};
      if (row.color == "RED") {
        stylejson.background = "#ffefef";
        return stylejson;
      } else if (row.color == "YELLOW") {
        stylejson.background = "#fffae5";
        return stylejson;
      } else {
        return "";
      }
    },
    //查看
    goDetails(row) {
      this.currentFinancialTask = row;
      this.dialogVisibleFinancial = true;
      this.isFinancialRecord = true;
    },
    handleCloseFinancialRecord() {
      this.isFinancialRecord = false;
      this.dialogVisibleFinancial = false;
      this.loadList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../collapsed.less";
.searchM {
  margin-bottom: 5px;
  .el-form-item {
    float: left;
  }
}
.searchL {
  width: calc(100% - 360px);
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }
  .order {
    padding: 0 8px;
    border-right: 1px solid #e5e5e5;
  }
  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
.finance {
  :deep(.el-table) .cell.el-tooltip {
    max-width: 250px;
  }
}
</style>
